import React, { useEffect } from 'react';
import { Spinner } from '@storyofams/react-ui';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useRouter } from 'next/router';

import { Seo } from '~components';
import { useShopify } from '~context';

const getDisplayName = (Component) =>
  Component.displayName || Component.name || 'Component';

// Add paths that B2B users can visit without logging in
const allowedPaths = [
  '/login',
  '/activate-account',
  '/forgot-password',
  '/reset-password',
];

export function withB2B(Component: any) {
  const B2BComponent = (props) => {
    const { customer, customerIsLoading } = useShopify();
    const router = useRouter();

    useEffect(() => {
      if (
        !customer &&
        !customerIsLoading &&
        !allowedPaths.some((allowed) => router.asPath.includes(allowed))
      ) {
        router.replace({
          pathname: `/login`,
          query: {
            to: router.pathname === '/' ? '/shop' : router.pathname,
            as: router.asPath === '/' ? '/shop' : router.asPath,
          },
        });
      }
    }, [customer, customerIsLoading]);

    if (customerIsLoading) {
      return (
        <>
          <Seo noIndex />
          <Spinner minHeight="400px" color="cocktails" />
        </>
      );
    }

    return <Component {...props} />;
  };

  hoistNonReactStatics(B2BComponent, Component);

  B2BComponent.displayName = `withB2B(${getDisplayName(Component)})`;

  return B2BComponent;
}
